<template>
  <a-spin :spinning="isLoading">
    <div class="hotline">
      <a-table
        :row-key="record => record.index"
        :columns="columns"
        :data-source="data"
        :pagination="{ pageSize: data.length }"
        :customRow="customRow"
        bordered
      >
        <span slot="index" slot-scope="text, record, index">
          <div>
            {{ index + 1 }}
          </div>
        </span>
        <span slot="action">
          <a-icon type="eye" />
        </span>
      </a-table>
    </div>
  </a-spin>
</template>

<script>
import moment from 'moment'
import { RepositoryFactory } from '@/api/RepositoryFactory'

const HotlineRepository = RepositoryFactory.get('hotlineRepository')

const columns = [
  {
    title: 'STT',
    width: '5%',
    key: 'index',
    scopedSlots: { customRender: 'index' }
  },
  {
    title: 'Tên danh sách',
    dataIndex: 'typeName',
    key: 'typeName',
    width: '21%'
  },
  {
    title: 'Tổng số cuộc gọi',
    dataIndex: 'data.total',
    width: '8%'
  },
  {
    title: 'Mục tiêu tự đặt',
    dataIndex: 'data.target',
    width: '8%'
  },
  {
    title: 'Số lượng cuộc gọi đã bắt máy',
    dataIndex: 'data.calledNum',
    width: '8%'
  },
  {
    title: 'Gọi chưa nghe máy',
    width: '25%',
    children: [
      {
        title: 'Không nghe máy',
        dataIndex: 'data.missNum',
        key: 'data.missNum'
      },
      {
        title: 'Thuê bao/Sai số',
        dataIndex: 'data.wrongNum',
        key: 'data.wrongNum'
      },
      {
        title: 'Đang bận/gọi lại sau',
        dataIndex: 'data.busy',
        key: 'data.busy'
      }
    ]
  },
  {
    title: 'Gọi có nghe máy',
    width: '25%',
    children: [
      {
        title: 'Chốt đặt lịch',
        dataIndex: 'data.book',
        key: 'data.book'
      },
      {
        title: 'Không quan tâm',
        dataIndex: 'data.doesNotCare',
        key: 'data.doesNotCare'
      },
      {
        title: 'Báo quay lại sau',
        dataIndex: 'data.comeBackLater',
        key: 'data.comeBackLater'
      }
    ]
  },
  {
    title: '',
    key: 'action',
    scopedSlots: { customRender: 'action' }
  }
]
export default {
  name: 'ListHotline',
  data() {
    return {
      data: [],
      isLoading: false,
      columns
    }
  },
  async created() {
    this.fetchHotline()
  },
  methods: {
    customRow(text) {
      return {
        on: {
          click: () => {
            const { type, typeName } = text
            this.$router.push({
              name: `DetailHotline`,
              params: {
                type,
                typeName
              }
            })
          }
        }
      }
    },
    async fetchHotline() {
      this.isLoading = true
      const today = moment().format('DD-MM-YYYY')
      try {
        const params = {
          date: today
        }
        const response = await HotlineRepository.getHotline(params)
        this.data = response
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss">
.hotline {
  .title {
    font-weight: bold;
    font-size: 24;
    text-transform: uppercase;
    margin: 15px 0;
  }
  .ant-table-row {
    cursor: pointer;
    background-color: #fff;
  }
  .ant-pagination {
    display: none;
  }
}
</style>
